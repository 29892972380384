<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            :to="{ name: 'main' }"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text v-if="authUser">
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Please verify your email
          </p>
          <p class="mb-2">
            Check your email inbox: {{ authUser.email }} to verify your email associated to your account
          </p>
        </v-card-text>

        <!-- verify email form -->
        <v-card-text>
          <p
            v-if="errorText"
            class="error--text font-weight-semibold"
          >
            {{ errorText }}
          </p>
          <p
            v-if="successText"
            class="success--text font-weight-semibold"
          >
            {{ successText }}{{ authUser.email }}
          </p>
          <v-btn
            block
            color="primary"
            class="mb-3"
            @click="emailVerified"
          >
            Email is verified
          </v-btn>

          <v-btn
            block
            color="info"
            :disabled="resentDone"
            @click="resendVerification"
          >
            Resend Verification to my Email
          </v-btn>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login'}"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, onUnmounted, ref } from 'vue'
import authuserStoreModule from './apps/authuser/authuserStoreModule'
import { auth } from './apps/firebaseConfig'

export default {
  setup() {
    const authUser = ref(null)
    const errorText = ref(null)
    const successText = ref(null)
    const resentDone = ref(false)
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const errorMessages = ref([])

    const AUTHUSER_APP_STORE_MODULE_NAME = 'app-authuser'

    // Register module
    if (!store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) {
      store.registerModule(AUTHUSER_APP_STORE_MODULE_NAME, authuserStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTHUSER_APP_STORE_MODULE_NAME)
    })

    auth.onAuthStateChanged(user => {
      store.dispatch('app-authuser/fetchUser', user)
      authUser.value = user
    })

    /*
    const user = computed(() => {
      console.log(store.getters.user)
      authUser.value = store.getters.user
    })
    console.log(user)
    */

    // const { user } = store.getters
    const fetchUserInfo = async user => {
      await store
        .dispatch('app-authuser/fetchUserInfo', {
          user,
        })
        .then(uinfo => {
          if (uinfo.info.admin) {
            uinfo.user.ability = [
              {
                action: 'read',
                subject: 'Public',
              },
              {
                action: 'read',
                subject: 'Admin',
              },
            ]
          } else {
            uinfo.user.ability = [
              {
                action: 'read',
                subject: 'Public',
              },
            ]
            if (uinfo.user.emailVerified) {
              uinfo.user.ability.push({
                action: 'read',
                subject: 'Editor',
              })
            }
          }

          const { ability: userAbility } = uinfo.user

          // localStorage.setItem('accessToken', uinfo.user.accessToken)
          localStorage.setItem('userData', JSON.stringify(uinfo))

          // Set user ability
          vm.$ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          delete uinfo.user.ability

          // On success redirect to home
          router.push('/')
        })
        .catch(err => {
          console.log(err.message)
          errorText.value = err.message
          errorMessages.value = err.message
        })
    }

    const emailVerified = () => {
      auth.currentUser.reload().then(() => {
        console.log(auth.currentUser.emailVerified)
        if (auth.currentUser.emailVerified) {
          console.log('Email is verified')
          fetchUserInfo(auth.currentUser)
        }
      })
    }

    const resendVerification = async () => {
      errorText.value = null
      successText.value = null

      await store
        .dispatch('app-authuser/emailVerify')
        .then(response => {
          console.log(response)
          resentDone.value = true
          successText.value = 'Email verification resent to: '
        })
        .catch(err => {
          console.log(err.message)
          errorText.value = err.message
        })
    }

    return {
      authUser,
      emailVerified,
      resendVerification,
      resentDone,
      successText,
      errorText,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
